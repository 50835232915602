import { AUTHORIZED_INS } from "./api";

const BRAND_API = {
  takeall: () => AUTHORIZED_INS.get("/brand"),
  createBrand: (data) => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => formData.append(key, value));

    return AUTHORIZED_INS.post("/brand/create", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  editBrand: (data) => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => formData.append(key, value));
    return AUTHORIZED_INS.post(`/brand/edit`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  deleteBrand: (id) => AUTHORIZED_INS.delete(`/brand/delete/${id}`),
};

export default BRAND_API;
