import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-body">
        <ul className="left-panel list-inline mb-0 p-0">
          <li className="list-inline-item">
            <a
              href="https://www.thrivecard.co.uk/index.php/content/privacy-policy/"
              className="footer_item"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </li>
          <li className="list-inline-item footer_item">
            <Link to="/admin/terms-of-service" className="footer_item">
              Terms of Use
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
