import React from "react";
import { Link } from "react-router-dom";
import VerticalNav from "../SidebarStyle/vertical-nav";

import Logo from "../../../../assets/images/Logo.svg";

const Sidebar = (props) => {
  return (
    <>
      <aside className="sidebar sidebar-default navs-rounded-all {{ sidebarVariants }}">
        <div className="sidebar-header d-flex align-items-center justify-content-center">
          <Link to="/admin" className="mainlogo">
            <img src={Logo} style={{ objectFit: "contain" }} alt="Logo" />
          </Link>
        </div>
        <div
          className="pt-0 sidebar-body data-scrollbar"
          data-scroll="1"
          id="my-scrollbar"
        >
          {/* sidebar-list class to be added after replace css */}
          <div className="sidebar-list navbar-collapse" id="sidebar">
            <VerticalNav />
          </div>
        </div>
        <div className="sidebar-footer"></div>
      </aside>
    </>
  );
};

export default Sidebar;
