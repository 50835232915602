import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sections: null,
};

const sectionSlice = createSlice({
  name: "sections",
  initialState,
  reducers: {
    takeAllSections: (state, action) => {
      state.sections = action.payload;
    },
    takeOneSection: (state, action) => {
      state.sections = action.payload;
    },
    addSection: (state, action) => {
      state.sections.push(action.payload);
    },
    editSection: (state, action) => {
      const sectionIndex = state.sections.findIndex(
        (section) => section.id === action.payload.id
      );
      if (sectionIndex !== -1) {
        state.sections[sectionIndex] = action.payload;
      }
    },
    deleteSection: (state, action) => {
      const res = state.sections.filter(
        (section) => section.id !== action.payload
      );
      state.sections = res;
    },
  },
});

export const {
  takeAllSections,
  takeOneSection,
  addSection,
  editSection,
  deleteSection,
} = sectionSlice.actions;

export default sectionSlice.reducer;
