import axios from "axios";

const STORAGE_KEY = {
  TOKEN: "token",
};

let baseURL = `${process.env.REACT_APP_API_KEY}/admin`;

const DEFAULT_INS = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

const AUTHORIZED_INS = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

AUTHORIZED_INS.interceptors.request.use((req) => {
  req.headers.Authorization = `Bearer ${localStorage.getItem(
    STORAGE_KEY.TOKEN
  )}`;
  return req;
});

DEFAULT_INS.interceptors.response.use(
  (response) => response.data,
  (error) =>
    Promise.reject(
      error?.response?.data || { message: "Oops, something went wrong" }
    )
);

AUTHORIZED_INS.interceptors.response.use(
  (response) => response.data,
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.removeItem(STORAGE_KEY.TOKEN);
      if (typeof window !== "undefined") {
        window.location.reload();
      }
    }
    if (error?.message === "canceled") {
      return Promise.reject(new Error("abort"));
    }
    return Promise.reject(
      error?.response?.data || { message: "Oops, something went wrong" }
    );
  }
);

export { DEFAULT_INS, AUTHORIZED_INS };
