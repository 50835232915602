import { lazy } from "react";
import { Switch, Route } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import Loadable from "../views/thrive/loader/Loadable";

const IndexCoupons = Loadable(
  lazy(() => import("../views/thrive/coupons/indexcoupons"))
);
const IndexCustomers = Loadable(
  lazy(() => import("../views/thrive/customers/indexcustomers"))
);
const IndexCategories = Loadable(
  lazy(() => import("../views/thrive/categories/indexcategories"))
);
const IndexSections = Loadable(
  lazy(() => import("../views/thrive/sections/indexsections"))
);
const IndexBrands = Loadable(
  lazy(() => import("../views/thrive/brands/indexbrands"))
);
const IndexRecommended = Loadable(
  lazy(() => import("../views/thrive/recommended/indexpromotion"))
);
const IndexWallet = Loadable(
  lazy(() => import("../views/thrive/wallet/indexwallet"))
);
const AllHistory = Loadable(
  lazy(() => import("../views/thrive/recommended/allhistory"))
);
const AllCouponsHistory = Loadable(
  lazy(() => import("../views/thrive/coupons/allCouponsHistory"))
);
const TermsofService = Loadable(
  lazy(() => import("../views/thrive/extra/terms-of-service"))
);

const ThriveRouter = () => {
  return (
    <>
      <TransitionGroup>
        <CSSTransition classNames="fadein" timeout={300}>
          <Switch>
            <Route path="/admin" exact component={IndexCoupons} />
            <Route
              path="/admin/coupons/history"
              exact
              component={AllCouponsHistory}
            />
            <Route path="/admin/customers" exact component={IndexCustomers} />
            <Route path="/admin/categories" exact component={IndexCategories} />
            <Route path="/admin/sections" exact component={IndexSections} />

            <Route path="/admin/brands" exact component={IndexBrands} />

            <Route
              path="/admin/recommended"
              exact
              component={IndexRecommended}
            />
            <Route path="/admin/wallet" exact component={IndexWallet} />
            <Route
              path="/admin/recommended/history"
              exact
              component={AllHistory}
            />
            <Route
              path="/admin/terms-of-service"
              exact
              component={TermsofService}
            />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </>
  );
};

export default ThriveRouter;
