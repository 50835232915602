import { AUTHORIZED_INS } from "./api";

const CUSTOMERS_API = {
  // takeall: () => AUTHORIZED_INS.get("/customer/get"),
  takeall: (data) => {
    return AUTHORIZED_INS.post("/customer/get", data, {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });
  },
  takeOne: (id) => AUTHORIZED_INS.get(`/customer/get/${id}`),
  takeWaitingToVerify: () => AUTHORIZED_INS.get("/customer/waiting-verify"),
  takeIdDocument: (id) =>
    AUTHORIZED_INS.get(`/customer/${id}/document`, { responseType: "blob" }),
  editCustomer: (data) => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => formData.append(key, value));
    return AUTHORIZED_INS.post(`/customer/edit/${data.id}`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  deleteCustomer: (id) => AUTHORIZED_INS.delete(`/customer/${id}/delete`),
  getAvailableStatuses: () =>
    AUTHORIZED_INS.get("/customer/available-statuses"),
  exportCustomersToXMLFile: () =>
    AUTHORIZED_INS.get("/customer/download-export", { responseType: "blob" }),
};

export default CUSTOMERS_API;
