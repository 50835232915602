import { cloneElement } from "react";
import Dialog from "@mui/material/Dialog";
import styled from "@mui/system/styled";

const StyledDialog = styled(Dialog)(({ padding }) => ({
  "& .MuiPaper-root": {
    padding,
    width: "calc(100% - 30px)",
    maxHeight: "calc(100% - 30px)",
    margin: "15px",
    "& .MuiPaper-root": {
      "&::-webkit-scrollbar": {
        width: "4px",
        height: "4px",
      },

      "&::-webkit-scrollbar-track": {
        background: "#ffffff",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.1)",
      },

      "&::-webkit-scrollbar-thumb": {
        border: "2px solid #355F51",
        backgroundColor: "#355F51",
      },
    },
  },
}));

const ModalLayout = ({
  isOpen,
  onClose,
  children,
  maxWidth = "lg",
  padding = "20px",

  ...childrenProps
}) => {
  const handleClose = (_, reason) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    if (onClose) {
      onClose();
    }
  };

  return (
    <StyledDialog
      open={isOpen}
      onClose={handleClose}
      fullWidth
      maxWidth={maxWidth}
      transitionDuration={{ enter: 200, exit: 0 }}
      padding={padding}
    >
      <div className="modalform">
        {cloneElement(children, {
          onCloseModal: handleClose,
          ...childrenProps,
        })}
      </div>
    </StyledDialog>
  );
};

export default ModalLayout;
