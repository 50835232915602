import { createSlice } from "@reduxjs/toolkit";

// question, text, confirmBtn, closeBtn, onConfirm, onClose, mode
const initialState = {
  modalState: {},
};

const confirmSlice = createSlice({
  name: "confirmSlice",
  initialState,
  reducers: {
    onConfirmModal: (state, { payload }) => {
      state.modalState = payload;
    },
    offConfirmModal: () => initialState,
  },
});

export const { onConfirmModal, offConfirmModal } = confirmSlice.actions;

export default confirmSlice.reducer;
