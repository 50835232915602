import React, { useState } from "react";
import { Row, Col, Image, Form, Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import AUTH_API from "../../../api/auth";

import Card from "../../../components/Card";

import Logo from "../../../assets/images/cards/FormLogo.png";

import { setToken } from "../../../store/slice/authSlice";

const SignIn = () => {
  let history = useHistory();
  const dispatch = useDispatch();

  const [validated, setValidated] = useState(false);

  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const handleSubmit = (event) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    event.preventDefault();

    AUTH_API.login(formData)
      .then((data) => {
        const token = data.response.token;

        if (data.status === "ok") {
          toast.dismiss();
          dispatch(setToken(token));
          localStorage.setItem("token", token);
          history.push("/admin");
        }
      })
      .catch(({ error }) => toast.error(error));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <>
      <section className="login-content">
        <Row className="m-0 align-items-center bg-white vh-100">
          <Row className="justify-content-center login-form">
            <Col md="10">
              <Card className="card-transparent shadow-none d-flex justify-content-center mb-0 auth-card">
                <Card.Body>
                  <Image className="login-form_img" src={Logo} alt="Logo" />
                  <h2 className="text-center login-form_title">Login</h2>
                  <Form
                    className="login-form_content"
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                    method="POST"
                  >
                    <Form.Group className="form-group">
                      <Form.Label
                        htmlFor="email"
                        className="login-form_content-label"
                      >
                        Email
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="login-form_content-input"
                        id="email"
                        name="username"
                        required
                        placeholder="Enter your login"
                        onChange={handleInputChange}
                        value={formData.username}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid email or username.
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="form-group">
                      <Form.Label
                        htmlFor="password"
                        className="login-form_content-label"
                      >
                        Password
                      </Form.Label>
                      <Form.Control
                        type="password"
                        className="login-form_content-input"
                        id="password"
                        name="password"
                        required
                        onChange={handleInputChange}
                        value={formData.password}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid password.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <div className="login-form_content-check">
                      <Form.Check className="form-check">
                        <Form.Check.Input
                          className="login-form_checkbox"
                          type="checkbox"
                          id="customCheck1"
                        />
                        <Form.Check.Label
                          htmlFor="customCheck1"
                          className="login-form_content-label"
                        >
                          Remember Me
                        </Form.Check.Label>
                      </Form.Check>
                      <Link to="#">Forgot Password?</Link>
                    </div>

                    <div className="d-flex justify-content-center">
                      <Button
                        variant="primary"
                        type="submit"
                        className="login-form_btn"
                      >
                        Login
                      </Button>
                    </div>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Row>
      </section>
    </>
  );
};

export default SignIn;
