import { combineReducers, configureStore } from "@reduxjs/toolkit";

import authSlice from "./authSlice";
import couponSlice from "./couponSlice";
import categorySlice from "./categorySlice";
import confirmSlice from "./confirmSlice";
import sectionSlice from "./sectionSlice";
import customersSlice from "./customersSlice";
import recommendedSlice from "./recommendedSlice";
import brandSlice from "./brandSlice";

const rootReducer = combineReducers({
  authSlice,
  couponSlice,
  categorySlice,
  confirmSlice,
  sectionSlice,
  customersSlice,
  recommendedSlice,
  brandSlice,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;
