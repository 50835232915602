import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allCoupons: null,
  activeCoupons: null,
  inactiveCoupons: null,
};

const couponSlice = createSlice({
  name: "coupon",
  initialState,
  reducers: {
    takeAllCoupons: (state, action) => {
      state.allCoupons = action.payload;
    },
    takeAllCouponsActive: (state, action) => {
      state.activeCoupons = action.payload;
    },
    takeAllCouponsInactive: (state, action) => {
      state.inactiveCoupons = action.payload;
    },
    takeOneCoupon: (state, action) => {
      state.allCoupons = action.payload;
    },
    addSimpleCoupon: (state, action) => {
      state.activeCoupons.push(action.payload);
    },
    addUniqueCoupon: (state, action) => {
      state.activeCoupons.push(action.payload);
    },
    editCoupon: (state, action) => {
      const activeCouponIndex = state.activeCoupons.findIndex(
        (coupon) => coupon.id === action.payload.id
      );
      if (activeCouponIndex !== -1) {
        state.activeCoupons[activeCouponIndex] = action.payload;
      }

      const inactiveCouponIndex = state.inactiveCoupons.findIndex(
        (coupon) => coupon.id === action.payload.id
      );
      if (inactiveCouponIndex !== -1) {
        state.inactiveCoupons[inactiveCouponIndex] = action.payload;
      }
    },
    deleteCoupon: (state, action) => {
      const activeCoupons = state.activeCoupons.filter(
        (coupon) => coupon.id !== action.payload
      );
      const inactiveCoupons = state.inactiveCoupons.filter(
        (coupon) => coupon.id !== action.payload
      );

      state.activeCoupons = activeCoupons;
      state.inactiveCoupons = inactiveCoupons;
    },
  },
});

export const {
  takeAllCoupons,
  takeAllCouponsActive,
  takeAllCouponsInactive,
  takeOneCoupon,
  addSimpleCoupon,
  addUniqueCoupon,
  editCoupon,
  deleteCoupon,
} = couponSlice.actions;

export default couponSlice.reducer;
